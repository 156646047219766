.container {
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    min-height: 100vh !important;
    padding: 20px 30px !important;
    background-color: #fff !important;
}

#layout_page .header li {
    display: inline-block;
    margin-right: 15px;
}

#layout_page .logo_text {
    font-size: 20px;
    font-weight: bold;
    color: #0053d7;
}

#layout_page .nav_ul li:not(:last-child):after {
    content: "|";
    font-size: 16px;
    color: #cdcbcb;
    padding-left: 20px;
}

#layout_page .nav_li {
    margin-left: 10px;
    margin-right: 10px;
}

#layout_page .nav_li a {
    font-size: 16px;
    font-weight: bold;
    color: #0053d7 !important;
    font-style: italic;
    text-transform: uppercase;
}

#layout_page .nav_li a:hover {
    /* color: #444444 !important; */
    text-decoration: underline !important;
}

.overlay_container {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #ffffffd9;
    left: 0;
    right: 0;
}

.overlay_container .loader_conatiner {
    margin: auto;
    width: 400px;
    margin-top: 150px;
    text-align: center;
}

.overlay_container .loader_conatiner .spinner {
    font-size: 24px;
    font-weight: bold;
}